import React from 'react';
import PropTypes from 'prop-types';
import { InView } from 'react-intersection-observer';

import { Grid } from 'components/Grid';
import { Heading, HeadingBoundary } from 'components/Heading';
import { Image } from 'components/Image';
import { Link } from 'components/Link';
import { Text } from 'components/Text';
import { cx } from 'utility';
import * as styles from './CategoryList.module.scss';

const propTypes = {
    className: PropTypes.string,
    items: PropTypes.array,
    itemsPerRow: PropTypes.number,
};

const defaultProps = {
    className: '',
    items: [],
    itemsPerRow: 2,
};

function getClasses({ className, itemsPerRow }) {
    const classes = cx(
        className,
        styles['categoryList'],
        styles[`categoryList${itemsPerRow}`],
    );

    return classes || undefined;
}

function CategoryList({
    className,
    items,
    itemsPerRow,
}) {
    const classes = getClasses({ className, itemsPerRow });

    return (
        <div className={classes}>
            <Grid>
                <div className={styles['container']}>
                    {items.map(({ category_description, category_emoji, category_link_url, category_title }, index) => {
                        const description = category_description?.text;
                        const emoji = category_emoji?.text;
                        const linkUrl = category_link_url;
                        const title = category_title?.text;

                        return (
                            <InView key={`category-list-item-${index}`} threshold={0.1} triggerOnce={true}>
                                {({ inView, ref, entry }) => {
                                    return (
                                        <div className={cx(styles['itemWrapper'], (inView) && styles['inview'])} ref={ref}>
                                            <div className={styles['item']}>
                                                <HeadingBoundary>
                                                    {emoji && (
                                                        <div className={styles['emojiContainer']}>
                                                            {emoji}
                                                        </div>
                                                    )}
                                                    <div className={styles['contentContainer']}>
                                                        {title && (
                                                            <Heading
                                                                className={styles['title']}
                                                                size={7}
                                                            >
                                                                <Link className={styles['link']} emphasis="high" to={linkUrl}>{title}</Link>
                                                            </Heading>
                                                        )}
                                                        {description && (
                                                            <Text
                                                                as="p"
                                                                className={styles['description']}
                                                                size={3}
                                                            >
                                                                {description}
                                                            </Text>
                                                        )}
                                                    </div>
                                                </HeadingBoundary>
                                            </div>
                                        </div>
                                    );
                                }}
                            </InView>
                        );
                    })}
                </div>
            </Grid>
        </div>
    );
}

CategoryList.propTypes = propTypes;
CategoryList.defaultProps = defaultProps;

export { CategoryList };
