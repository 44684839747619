import React from 'react';
import { BlogList } from 'patterns/BlogList';
import { CategoryList } from 'patterns/CategoryList';
import { Masthead } from 'patterns/Masthead';
import { Shell } from 'patterns/Shell';

import '../scss/main.scss';

function HomePage({
    location: {
        pathname,
    },
}) {
    const data = {
        masthead: {
            title: 'I’m Rob. A designer, developer & creator',
            description: [
                {
                    spans: [],
                    text: 'On this site, we’ll explore topics around business, health and living a more intentional life',
                    type: 'paragraph',
                },
            ],
            image: {
                alt: '',
                dimensions: {
                    height: 1536,
                    width: 2048,
                },
                url: 'https://images.prismic.io/robsimpson/af40bb5b-2bc8-4a0f-8827-46cdf6f0cde2_about-cover.jpg?auto=compress,format'
            },
        },
        categories: {
            items: [
                {
                    category_description: {
                        text: 'A collection of tools and products I use to run my successful (in my eyes) freelance business',
                    },
                    category_emoji: {
                        text: '👝',
                    },
                    category_link_url: {
                        link_type: 'Document',
                        uid: 'business',
                    },
                    category_title: {
                        text: 'Business',
                    },
                },
                {
                    category_description: {
                        text: 'Some of my favourite products and tools I use to lead a healthy – hopefully long life',
                    },
                    category_emoji: {
                        text: '🍏',
                    },
                    category_link_url: {
                        link_type: 'Document',
                        uid: 'health',
                    },
                    category_title: {
                        text: 'Health',
                    },
                },
            ]
        },
        blog: {
            items: [
                {
                    card_description: 'Reflecting on 2020 – a year that included the most change in my life personally and professionally for a long time.',
                    card_image: {
                        alt: '',
                        dimensions: {
                            height: 1024,
                            width: 768,
                        },
                        url: 'https://images.prismic.io/robsimpson/2f4a907b-376a-4174-8762-734975b18bd1_about-zoo.png?auto=compress,format'
                    },
                    card_link_url: {
                        link_type: 'Document',
                        uid: 'articles/2020-year-in-review',
                    },
                    card_title: {
                        text: '2020 year in review',
                    },
                },
                {
                    card_description: 'From the 7th September, I’ll be available for design and development projects on a full-time basis.',
                    card_image: {
                        alt: '',
                        dimensions: {
                            height: 3024,
                            width: 4032,
                        },
                        url: 'https://images.prismic.io/robsimpson/b654c40f-f586-4b69-8fdb-9ff3018fa444_about-office.jpg?auto=compress,format'
                    },
                    card_link_url: {
                        link_type: 'Document',
                        uid: 'articles/full-time-freelance-designer-developer',
                    },
                    card_title: {
                        text: 'Starting a new chapter as a full time freelancer',
                    },
                },
            ],
        },
    };

    return (
        <Shell
            pageDescription={data.masthead.description[0].text}
            pageSocialImage=""
            pageTitle={data.masthead.title}
            pathname={pathname}
        >
            <Masthead
                description={data.masthead.description}
                image={data.masthead.image}
                title={data.masthead.title}
                titleSmall={true}
                titleMobileSmall={true}
            />
            <CategoryList
                items={data.categories.items}
            />
            <BlogList
                items={data.blog.items}
            />
        </Shell>
    );
}

export default HomePage;
