// extracted by mini-css-extract-plugin
export var container = "CategoryList-module--container--1EdbE";
export var itemWrapper = "CategoryList-module--itemWrapper--2lKtQ";
export var inview = "CategoryList-module--inview--3AhZ9";
export var item = "CategoryList-module--item--3lo9P";
export var emojiContainer = "CategoryList-module--emojiContainer--11tfw";
export var title = "CategoryList-module--title--3PN3K";
export var link = "CategoryList-module--link--5FdJq";
export var description = "CategoryList-module--description--WTjCF";
export var show = "CategoryList-module--show--2mxGR";
export var showFadeInDown = "CategoryList-module--show-fade-in-down--55Bcc";
export var showFadeInUp = "CategoryList-module--show-fade-in-up--5ifDb";
export var hideFadeOut = "CategoryList-module--hide-fade-out--3KnQ7";